<template>
  <div class="laboratoryContainer">
    <div class="a_top">
      <img class="image" :src="laboratory.banner" alt="banner" />
    </div>
    <div class="a_main">
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section1_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section1_content }}
      </div>
      <img class="image" :src="laboratory.section1Bg1" alt="" />

      <!-- 1. 数字化设计实训平台 -->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section2_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section2_content }}
      </div>
      <img class="image" :src="laboratory.section2Bg1" alt="" />
      <div class="a_sub_descript margin_top_24 font-size-18 font-weight-400">
        {{ laboratory.section2_content2 }}
      </div>
      <div class="a_sub_descript margin_bottom_24 font-size-18 font-weight-400">
        {{ laboratory.section2_content3 }}
      </div>
      <img class="image" :src="laboratory.section2Bg2" alt="" />

      <!-- 1.1 道路勘测模拟训练软件 -->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section3_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section3_content }}
      </div>
      <img class="image" :src="laboratory.section3Bg1" alt="" />
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section3_content2 }}
      </div>
      <img class="image" :src="laboratory.section3Bg2" alt="" />
      <div
        class="margin_top_12 text-align-center font-size-24 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section3Bg2_title }}
      </div>

      <!-- 1.2 道路数字设计软件 -->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section4_title }}
      </div>
      <div class="margin_top_24 li_title font-size-18 font-weight-400">
        {{ laboratory.section4_content1 }}
      </div>
      <div class="a_sub_descript margin_bottom_24 font-size-18 font-weight-400">
        {{ laboratory.section4_content1_2 }}
      </div>
      <img class="image" :src="laboratory.section4_content1_Bg1" alt="" />
      <div
        class="margin_top_15 margin_bottom_24 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section4_content1_Bg1_title }}
      </div>
      <img class="image" :src="laboratory.section4_content1_Bg2" alt="" />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section4_content1_Bg2_title }}
      </div>

      <div class="li_title margin_top_24 font-size-18 font-weight-400">
        {{ laboratory.section4_content2 }}
      </div>
      <div class="a_sub_descript font-size-18 font-weight-400">
        {{ laboratory.section4_content2_1 }}
      </div>
      <div class="a_sub_descript font-size-18 font-weight-400">
        {{ laboratory.section4_content2_2 }}
      </div>
      <img
        class="margin_top_24"
        :src="laboratory.section4_content2_Bg1"
        alt=""
      />
      <div
        class="margin_top_15 margin_bottom_24 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section4_content2_Bg1_title }}
      </div>
      <img class="" :src="laboratory.section4_content2_Bg2" alt="banner" />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section4_content2_Bg2_title }}
      </div>

      <!-- 1.3 道路模拟驾驶与安全性评价系统 -->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section5_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section5_content1 }}
      </div>
      <img class="image" :src="laboratory.section5_content1_bg1" alt="" />
      <div
        class="margin_top_15 margin_bottom_24 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section5_content1_Bg1_title }}
      </div>
      <img class="image" :src="laboratory.section5_content1_Bg2" alt="" />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section5_content1_Bg2_title }}
      </div>

      <!-- 1.4 数字化汇报-->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section6_title }}
      </div>
      <div class="a_sub_descript margin_top_24 font-size-18 font-weight-400">
        {{ laboratory.section6_content1 }}
      </div>
      <div class="a_sub_descript margin_bottom_24 font-size-18 font-weight-400">
        {{ laboratory.section6_content2 }}
      </div>
      <img
        class="image margin_top_12"
        :src="laboratory.section6_content1_bg1"
        alt=""
      />
      <img
        class="image margin_top_24"
        :src="laboratory.section6_content1_Bg2"
        alt=""
      />

      <!-- 2. 智能建造仿真平台-->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section7_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section7_content1 }}
      </div>
      <img class="image" :src="laboratory.section7_content1_bg1" alt="" />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section7_content1_bg1_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section7_content2 }}
      </div>
      <img class="image" :src="laboratory.section7_content1_Bg2" alt="" />

      <!-- 2.1 数字化建管-->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section8_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section8_content1 }}
      </div>
      <img class="image" :src="laboratory.section8_content1_bg1" alt="" />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section8_content1_bg1_title }}
      </div>

      <!-- 2.2 数字化施工-->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section9_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section9_content1 }}
      </div>
      <img class="image" :src="laboratory.section9_content1_bg1" alt="" />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section9_content1_bg1_title }}
      </div>

      <!-- 2.3 智能化制造-->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section10_title }}
      </div>
      <img
        class="image margin_top_24"
        :src="laboratory.section10_content1_bg1"
        alt=""
      />
      <div
        class="margin_top_15 text-align-center font-size-18 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section10_content1_bg1_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section10_content1 }}
      </div>

      <!-- 2.4 教学仿真资源库-->
      <div
        class="a_m_title text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
      >
        {{ laboratory.section11_title }}
      </div>
      <div class="a_m_descript font-size-18 font-weight-400">
        {{ laboratory.section11_content1 }}
      </div>
      <img class="image" :src="laboratory.section11_content1_bg1" alt="" />
    </div>
  </div>
</template>

<script>
import laboratoryData from "../../../i18n/langs/zh.js";
export default {
  data() {
    return {
      laboratory: laboratoryData.laboratory,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.laboratoryContainer {
  padding-bottom: 200px;
  .a_top {
    margin-bottom: 64px;
  }
  .image {
    margin: auto;
    display: flex;
    object-fit: contain;
  }
  .a_main {
    width: 1200px;
    margin: auto;
    .a_m_title {
      margin-top: 40px;
    }
    .a_m_descript {
      color: rgba(29, 29, 31, 0.8);
      line-height: 36px;
      letter-spacing: 2px;
      text-indent: 36px;
      margin-top: 24px;
      text-align: justify;
      margin-bottom: 24px;
    }
    .li_title {
      color: rgba(29, 29, 31, 0.8);
      line-height: 36px;
      letter-spacing: 2px;
      text-align: justify;
    }
    .a_sub_descript {
      color: rgba(29, 29, 31, 0.8);
      line-height: 36px;
      letter-spacing: 2px;
      text-indent: 36px;
      text-align: justify;
    }
    .margin_top_24 {
      margin-top: 24px;
    }
    .margin_top_12 {
      margin-top: 12px;
    }
    .margin_top_15 {
      margin-top: 15px;
    }
    .margin_bottom_24 {
      margin-bottom: 24px;
    }
  }
}
</style>
